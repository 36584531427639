import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { Error400 } from '../../../../components/Errors/400/Error400';
import { Error403 } from '../../../../components/Errors/403/Error403';
import { Error404 } from '../../../../components/Errors/404/Error404';
import { Error500 } from '../../../../components/Errors/500/Error500';
import { Footer } from '../../../../components/Footer/Footer';

function ErrorPage({ code }: { code: '400' | '403' | '404' | '500' }) {
  const { formatMessage } = useIntl();
  const path = useLocation().pathname;

  // Log a warning in Sentry
  Sentry.captureMessage(`Error page ${code} - path: ${path}`, 'warning');

  return (
    <>
      <Helmet title={formatMessage({ id: `page.${code}.head.title` })} titleTemplate="%s | Pledge" />

      <div id="ErrorPage" className="mx-auto flex min-h-full max-w-[75rem] flex-col gap-4 p-5">
        <main className="flex grow items-center">
          {{
            '400': <Error400 iconColor="default" />,
            '403': <Error403 iconColor="default" />,
            '404': <Error404 iconColor="default" />,
            '500': <Error500 iconColor="default" />,
          }[code] ?? <Error500 />}
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
}

export function FallbackPage() {
  return (
    <div className="ErrorPage">
      <Helmet title="Unexpected Error" titleTemplate="%s | Pledge" />
      <div className="ErrorPage__content">
        <Error500 />
      </div>
      <Footer />
    </div>
  );
}

// eslint-disable-next-line import/no-default-export -- lazy React component
export default ErrorPage;
